<template>
  <DescriptorCard
    class_name="ReplenishmentOrderDate"
    title="ReplenishmentOrderDates"
    v-bind="Object.assign({}, $attrs)"
    :_predefined_filters="predefined_filters"
    :_property_cell_variants="property_cell_variants"
    :fields="fields_"
    :enable_add="false"
  >
    <!-- <template v-slot:btn="row"
      >
      <b-button-toolbar class="table_actions" :class="$mq == 'xs' ? 'float-right' : 'float-left'">
        <b-button-group size="sm">
          <b-button @click="" variant="outline-secondary">
            <i class="fa fa-"></i>
          </b-button>
        </b-button-group>
      </b-button-toolbar>
      
    </template> -->
  </DescriptorCard>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
  components: {
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
  },
  props: {},
  data: () => {
    return {};
  },
  computed: {
    fields_() {
      let fields = [];

      //fields = fields.concat([{ key: 'btn', _slot_name: 'btn', label: 'Button', tdClass: ['no_break'] }]);

      fields = fields.concat(this.$d.getClassBTableFields('ReplenishmentOrderDate'));

      console.log('fields_', fields);
      return fields;
    },
    property_cell_variants() {
      return [
        {
          property_key: 'order_id',
          cellVariantFunc: params => {
 
            let range_week = moment().add(14, 'day').format('YYYY-MM-DD');
            let range_min = moment(params['created_at']).add(1.5, 'hours').format('YYYY-MM-DD -- hh:mm:ss A');
            let current_time = moment().format('YYYY-MM-DD -- hh:mm:ss A');

            if (params['order_id'] != null) {
              return 'success';
            } else if (params['order_id'] == null && range_week >= params['date']
            && range_min <= current_time) {
              return 'danger';
            } else if (params['order_id'] == null && range_week >= params['date'] 
            && range_min >= current_time) {
              return 'warning';
            }
            return null;          
          },
        },
      ];
    },
    predefined_filters() {
      return [
        {
          filter_name: 'Is Tomorrow',
          filter_description: 'Replenishment Dates that are for tomorrow',
          filter_params: {
            filters: [
              {
                filter_type: 'expression',
                operator: 'or',
                filters: [
                  {
                    filter_type: 'property',
                    existence: 'present',
                    property_key: 'date', 
                    equal: this.$d.momentFormatDate(this.$d.momentSynced().add(1, 'day')),
                  },
                ],
              },
              /* {
                filter_type: 'property',
                existence: 'present',
                property_key: 'is_confirmed',
                equal: false,
              }, */
            ],
          },
          frontend: {
            // variant: 'success',
            variant: 'dark',
          },
        },
        {
          filter_name: 'Is Today',
          filter_description: 'Replenishment Dates that are for today',
          filter_params: {
            filters: [
              {
                filter_type: 'expression',
                operator: 'or',
                filters: [
                  {
                    filter_type: 'property',
                    existence: 'present',
                    property_key: 'date', 
                    equal: this.$d.momentFormatDate(this.$d.momentSynced()),
                  },
                ],
              },
              /* {
                filter_type: 'property',
                existence: 'present',
                property_key: 'is_confirmed',
                equal: false,
              }, */
            ],
          },
          frontend: {
            // variant: 'success',
            variant: 'primary',
          },
        },
        {
          filter_name: 'Is Overdue',
          filter_description: 'Replenishment Dates that are not confirmed & late',
          filter_params: {
            filters: [
              {
                filter_type: 'expression',
                operator: 'and',
                filters: [
                  {
                    filter_type: 'property',
                    existence: 'present',
                    property_key: 'order_id',
                    equal: null,
                  },
                  {
                    filter_type: 'property',
                    existence: 'present',
                    property_key: 'date', 
                    max: this.$d.momentFormatDate(this.$d.momentSynced().add(2, 'weeks')),
                  },
                ],
              },
              /* {
                filter_type: 'property',
                existence: 'present',
                property_key: 'is_confirmed',
                equal: false,
              }, */
            ],
          },
          frontend: {
            // variant: 'success',
            variant: 'danger',
          },
        },
      ];
    },
  },
  created() {
    this._ = _;
  },
  methods: {
  
  },
};
</script>
